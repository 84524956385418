import React, { useEffect, useState } from "react";
import "./App.css";
import hadithData from "./data/hadith-data.json";
// import BottomControls from "./components/BottomControls";
import HadithCard from "./components/HadithCard";

function App() {
    const [hadith, setHadith] = useState<{}[]>([]);
    const active = "adnan";

    let activeUrl = window.location.host
        .toLowerCase()
        .includes("moeabdalla.com".toLowerCase());

    useEffect(() => {
        setHadith([...Object.values(hadithData)]);
    }, []);

    const hadithMarkup = hadith.map((zikr: any, index) => (
        <HadithCard data={zikr} index={index} key={active + "-" + index + 1} />
    ));

    return (
        <div className="max-h-screen shadow-xl flex flex-col items-center bg-emerald-500">
            {!activeUrl && (
                <h1 className="text-3xl text-center w-full font-extrabold bg-white text-red-500">
                    هذا التطبيق سينتقل إلى{" "}
                    <a
                        className="text-blue-400"
                        target={"_blank"}
                        rel={"noreferrer"}
                        href="https://adnan.azkar.moeabdalla.com"
                    >
                        adnan.azkar.moeabdalla.com
                    </a>
                </h1>
            )}
            <div className="flex flex-row justify-around max-w-xl p-2 rounded-tr-lg rounded-tl-lg bg-white">
                <h1 className=" text-3xl font-bold underline pr-10">
                    adnan.azkar.
                </h1>
                <button
                    className={`rounded-lg px-4 ${
                        active === "adnan" && "border-green-400 border-4"
                    }`}
                >
                    احاديث
                </button>
            </div>
            <div className="flex-1 overflow-scroll p-4 gap-4 max-w-xl bg-white rounded-tr-lg rounded-tl-lg">
                {hadithMarkup}
            </div>
        </div>
    );
}

export default App;
