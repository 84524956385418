import React, { useEffect, useState } from "react";

export interface HadithCardProps extends React.HTMLAttributes<HTMLDivElement> {
    data?: {
        content: string;
        source: string;
        id: number;
    };
    index?: number;
    key?:string;
}

const HadithCard: React.FC<HadithCardProps> = ({
    data = { count: 1 },
    index = 0,...HadithCardProps
}) => {
    const [count, setCount] = useState(0);
    const [completed, setCompleted] = useState(false);

    const isVibrationSupported = navigator.vibrate !== undefined;

    function vibrateOnTap() {

        if (isVibrationSupported) {
          navigator.vibrate(200); // Adjust duration (ms) as needed
        } else {
          console.warn('Vibration API not supported by your browser.');
        }
      }

    useEffect(() => {
        if (count >= 1) {
            setCompleted(true);
        }
    }, [count]);

    const increment = () => {
        vibrateOnTap();

        setCount((prev) => prev + 1);
    };

    const reset = () => {
        setCount(0);
        setCompleted(false);
    };

    let cardMarkup = data ? (
        <div
        {...HadithCardProps}
            dir="rtl"
            className={`p-2 my-4 rounded-3xl  shadow-green-500 bg-opacity-25 outline ${
                !completed && count > 0 && "bg-amber-300 shadow-md outline-none"
            } ${
                completed && "bg-emerald-400 outline-none"
            } flex flex-col justify-center text-center`}
        >
            
            <div className="flex flex-row-reverse content-between  bg-green-200 rounded-3xl text-gray-400">
                <button
                    className="px-4 "
                    onClick={reset}
                >
                    مسح
                </button>
                {/* <p>{index + 1}</p> */}
            </div>
            <div onClick={increment}>
                <p className="text-lg">{data.content}</p>
                <p className="text-[#FFD700]">{data.source}</p>
            </div>
        </div>
    ) : (
        <div>'data missing'</div>
    );
    return cardMarkup;
};

export default HadithCard;
